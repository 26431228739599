const Contact = () => {
  return (
    <section id='contact'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 col-lg-offset-2'>
            <h2 className='section-heading text-center'>
              Contact MRL Ventures Fund
            </h2>
            <div className='hr-divider light' />
            <p className='text-center'>
              MRL Ventures Fund is actively developing a portfolio of
              early-stage therapeutic companies, which are focused on delivering
              innovative therapies to improve patient care. <br />
              <br />
            </p>
            <div className='row'>
              <div className='col-lg-8 col-md-8 col-sm-8'>
                <div className='embed-responsive embed-responsive-16by9'>
                  <iframe
                    className='embed-responsive-item'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11790.951671708093!2d-71.09222423906553!3d42.36942434907521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370b0292c16c3%3A0x3a881c7ea3e6df01!2s320+Bent+St%2C+Cambridge%2C+MA+02141!5e0!3m2!1sen!2sus!4v1512053546620'
                    width='100%'
                    height='300px'
                    frameborder='0'
                    style={{ border: 0, borderRadius: '10px' }}
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4'>
                <p className='text-left'>
                  <strong>MRL Ventures Fund</strong>
                  <br />
                  320 Bent Street
                  <br />
                  4th Floor
                  <br />
                  Cambridge, MA 02141
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
