import Nav from "./components/Nav";
import About from "./pages/About";
import InvestmentFocus from "./pages/InvestmentFocus";
import Portfolio from "./pages/Portfolio";
import OurTeam from "./pages/OurTeam";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

//  App.js
//  This file contains the basic layout of the site.
//  All pages and layout components are imported above, and added to the site as components below.

function App() {
  return (
    <div className="App">
      <Nav />
      <About />
      <InvestmentFocus />
      <Portfolio />
      <OurTeam />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
