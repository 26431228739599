const InvestmentFocus = () => {
  return (
    <>
      <a className='scroll-offset' id='investment' />
      <section className='bg-primary' id='investment'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 id='investment-heading' className='section-heading'>
                Delivering More than Capital
              </h2>
              <div className='hr-divider light' />
            </div>
          </div>

          <div className='row text-center pt-25' id='boxes'>
            <div className='col-lg-6'>
              <div className='box'>
                <h2>Investments</h2>
                <p>Seed and early-stage company investments</p>
                <p>
                  Therapeutics companies—from concept to IND. Investing up to $25
                  million per company, providing long-term, and committed
                  capital
                </p>
                <p>
                  Lead or join a syndicate of investors and play an active role
                  on company boards
                </p>
                <p>Transparent and streamlined investment process</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='box' id='box-2'>
                <h2>Company Involvement</h2>
                <p>
                  Over the life of the company, the MRLV team will continue to
                  provide capital and expertise. We protect the confidentiality
                  of our current and future portfolio companies by providing a
                  robust firewall from the rest of the corporation.
                </p>
                <p>
                  At a portfolio company’s choosing, MRLV investors can act as a
                  conduit to the larger corporate organization to solicit advice
                  / expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvestmentFocus
