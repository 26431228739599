import PortfolioCompany from '../components/PortfolioCompany'
import companies from '../utils/companies'

const Portfolio = () => {
  return (
    <>
      <a className='scroll-offset' href='#' id='portfolio'></a>
      <section className='portfolio-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='section-heading' id='portfolio-header'>
                Our Portfolio Companies
              </h2>
              <br />
              <br />
              <h3 className='section-heading'>Active</h3>
              <div className='hr-divider teal' />
              <div className='row'>
                {companies.active.map(company => (
                  <PortfolioCompany
                    name={company.name}
                    url={company.url}
                    img={company.img}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='portfolio-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='section-heading'>Acquired & IPOs</h2>
              <div className='hr-divider teal' />
              <div className='row'>
                {companies.acquired.map(company => (
                  <PortfolioCompany
                    name={company.name}
                    url={company.url}
                    img={company.img}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio
