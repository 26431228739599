const PortfolioCompany = (props) => {
  const { url, img, name } = props;

  return (
    <div className="col-lg-3 portfolio-item">
      <a target="_blank" rel='noreferrer' href={url} className='portfolio-box'>
        <img src={img} className="img-responsive" alt="" />
        <div className={`portfolio-box-caption ${!name && 'no-hover'}`}>
          <div className="portfolio-box-caption-content">
            <div className="project-name">{name}</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PortfolioCompany;
