import Person from '../components/Person'
import people from '../utils/people'

const OurTeam = () => {
  return (
    <section id='ppl'>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-lg-12 text-center'>
            <h2 className='section-heading'>Our Team</h2>
            <div className='hr-divider light' />
            <p className='text-center'>
              The MRLV team of investors has years of accumulated experience as
              scientists, investors, entrepreneurs, and business development
              &amp; licensing leaders in both biotechnology and pharmaceutical
              companies. This broad experience enables MRLV investors a rich
              engagement with portfolio companies and co-investors.{' '}
            </p>
          </div>
        </div>
        
        
        {/* <div className="row justify-content-center">
          {people.slice(2, 4).map((person) => (
            <Person
              name={person.name}
              title={person.title}
              dataTarget={person.dataTarget}
              image={person.image}
              paraOne={person.paraOne}
              paraTwo={person.paraTwo}
            />
          ))}
        </div> */}
      </div>
      <div class="container-fluid">
          <div className='row justify-content-center'>
            {people.slice(0, 5).map((person) => (
              <Person
                name={person.name}
                title={person.title}
                dataTarget={person.dataTarget}
                image={person.image}
                paraOne={person.paraOne}
                paraTwo={person.paraTwo}
              />
            ))}
          </div>
        </div>
    </section>
  )
}

export default OurTeam
