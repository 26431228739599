const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-sm-12">
            <p>
            Copyright © 2022 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.
            </p>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 text-end footer-links">
            <a target="_blank" href="http://www.msd.com/privacy/" rel="noreferrer">
                Privacy
              </a>
              <a
                target="_blank"
                href="http://www.msd.com/policy/terms-of-use/home.html" rel="noreferrer"
              >
                Terms of Use
              </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
