import { useState } from 'react'
import { act } from 'react-dom/test-utils'
import logo from '../assets/images/logo.png'

const Nav = () => {
  const [activeLink, setActiveLink] = useState('')

  console.log(activeLink)

  return (
    <nav className='navbar navbar-expand-lg navbar-dark navbar-default fixed-top'>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img src={logo} width='330px' alt='MRLV logo' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarNavAltMarkup'
        >
          <div className='navbar-nav'>
            <a
              className={`nav-link text-light ${
                activeLink === 'about' && 'green-link'
              }`}
              aria-current='page'
              href='#about'
              onClick={() => setActiveLink('about')}
            >
              ABOUT US
            </a>
            <a
              className={`nav-link text-light ${
                activeLink === 'investment' && 'green-link'
              }`}
              href='#investment'
              onClick={() => setActiveLink('investment')}
            >
              INVESTMENT FOCUS
            </a>
            <a
              className={`nav-link text-light ${
                activeLink === 'portfolio' && 'green-link'
              }`}
              href='#portfolio'
              onClick={() => setActiveLink('portfolio')}
            >
              PORTFOLIO
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
