import PersonModal from './PersonModal'

const Person = props => {
  return (
    <>
      <div className='col-md-2 col-md-offset-2 text-center'>
        <img src={props.image} />
        <p>
          {props.name}
          <br />
          <span>{props.title} </span>
        </p>
        <button
          type='button'
          className='btn btn-default btn-white-border'
          data-bs-toggle='modal'
          data-bs-target={`#${props.dataTarget}`}
        >
          Biography
        </button>
      </div>
      <PersonModal
        dataTarget={props.dataTarget}
        image={props.image}
        modalTitle={props.name}
        title={props.title}
        paraOne={props.paraOne}
        paraTwo={props.paraTwo}
      />
    </>
  )
}

export default Person
