const PersonModal = props => {
  return (
    <div
      class='modal fade'
      id={props.dataTarget}
      tabindex='-1'
      role='dialog'
      aria-labelledby={`${props.dataTarget}Label`}
      aria-hidden='true'
    >
      <div class='modal-dialog' role='document'>
        <div class='modal-content'>
          <button
            type='button'
            class='btn btn-secondary modal-btn'
            data-bs-dismiss='modal'
          >
            ✕
          </button>
          <div class='row'>
            <div class='col-4'>
              <img src={props.image} width='150px' />
            </div>
            <div class='col-8'>
              <div class='modal-header text-left justify-content-left'>
                <h5
                  class='modal-title text-teal font-weight-bold left-text'
                  id={`${props.dataTarget}Label`}
                  style={{ lineHeight: 1.2 }}
                >
                  {props.modalTitle}
                  <br />
                  <em>
                    <span class='text-blue left-text'>{props.title}</span>
                  </em>
                </h5>
              </div>
              <div class='modal-body'>
                <p className='text-muted left-text'>{props.paraOne}</p>
                <p className='text-muted left-text'>{props.paraTwo}</p>
              </div>
              <div class='modal-footer'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonModal
