import downIcon from '../assets/images/icon_down.png'
import evergreenIcon from '../assets/images/icon_evergreen.png'
import handshakeIcon from '../assets/images/icon_handshake.png'
import totalraiseIcon from '../assets/images/icon_total.png'
import doctorIcon from '../assets/images/icon_doctor.png'

const About = () => {
  return (
    <div className='container-fluid'>
      <header>
        <div class='header-content'>
          <div class='header-content-inner'>
            <h1 id='homeHeading'>
              MRL Ventures Fund <br />
              Investing in the Future
            </h1>
            <hr class='white' />
            <p class='hometext'>
              The MRL Ventures Fund (MRLV) team invests globally in early-stage,
              preclinical therapeutics companies to transform patient care.{' '}
            </p>
            <p class='hometextsm'>
              Explore how the MRLV team of experienced life-sciences venture
              capital professionals are committed to supporting great
              entrepreneurs in their quest to create value through building
              companies that have a meaningful impact on health and disease.
            </p>
            <a id='page-scroll' href='#about'>
              <img src={downIcon} />
            </a>
          </div>
        </div>
      </header>

      <a className='scroll-offset' href='#' id='about'></a>
      <section class='bg-offwhite'>
        <div class='container'>
          <div class='row justify-content-center'>
            <div class='col-lg-8 col-lg-offset-2 text-center justify-content-center'>
              <h2 class='section-heading'>About Us</h2>
              <div class='hr-divider light'></div>

              <p>
              The MRL Ventures Fund (MRLV) is the corporate venture arm of Merck &amp; Co., Inc., Rahway, NJ USA, and is located in Cambridge, MA.
              </p>
              <p class='pt-25'>
                <strong>The MRLV team is:</strong>
              </p>
            </div>
          </div>

          <div class='row threetypes'>
            <div class='col-lg-4'>
              <div class='typeone'>
                <p class='text-blue'>
                  Looking for the highest quality, differentiated science that
                  will redefine patient care
                </p>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='typetwo'>
                <p class='text-blue'>
                  Committed to backing imaginative scientists and proven
                  entrepreneurs{' '}
                </p>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='typethree'>
                <p class='text-blue'>
                  Agnostic to therapeutic area or modality
                </p>
              </div>
            </div>
          </div>

          <br />
          <div class='row pt-25 justify-content-center'>
            <div class='col-lg-8 text-center'>
              <p>
                The MRLV investors do not ask for any product or technology
                rights as a condition of investment. We do not invest in medical
                devices, diagnostic platforms or digital health. For digital
                health opportunities, please visit{' '}
                <span style={{ 'white-space': 'nowrap' }}>
                  <a
                    href='http://msdghifund.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Global Health Innovation Fund (GHI)
                  </a>
                  .
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='services'>
        <div class='container'>
          <div class='row'>
            <div class='col-lg-12 text-center'>
              <h2 class='section-heading text-teal'>BY THE NUMBERS</h2>
              <div class='hr-divider teal'></div>
            </div>
          </div>
          <div class='row pt-25 justify-content-center'>
            <div class='col-md-3'>
              <div class='text-center'>
                <img src={evergreenIcon} alt='' />
                <p>
                  <span class='boldstat'>
                    $500 million of committed capital
                  </span>
                </p>
                <p></p>
              </div>
            </div>
            <div class='col-md-3'>
              <div class='text-center'>
                <img src={totalraiseIcon} alt='' />
                <p>
                  <span class='boldstat'>
                  &gt;$6.5 BILLION
                    <br />
                    Total Capital Raised for Portfolio
                  </span>
                </p>
              </div>
            </div>
            <div class='col-md-3'>
              <div class='text-center'>
                <img src={handshakeIcon} alt='' />
                <p>
                  <span class='boldstat'>$6.3+ BILLION IN ACQUISITIONS IN THE LAST 5 YEARS</span>
                </p>
              </div>
            </div>
            <div class='col-md-3'>
              <div class='text-center'>
                <img src={doctorIcon} alt='' />
                <p>
                  <span class='boldstat'>27+ Clinical Programs</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
